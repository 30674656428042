import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Icon from '../../ui/Icon';
import { Link, useParams } from "react-router-dom";
import resources from '../../api';
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import {
    FiSave
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";

export default function Edit() {

    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({})
    const { id } = useParams();
    const toast = useToast()
    const navigate = useNavigate();


    async function read() {
        let res = await resources.company.readOne(id)
        if (res) {
            setData(res.data)
        }
    }
    async function update(e) {
        e.preventDefault()

        if (e.target.img) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {

                setLoader(true)
                let res = resources.company.update(id, {
                    name: e.target.name.value,
                    description: e.target.description.value,
                    logo: reader.result

                }, localStorage.getItem("userToken"))

                if (res) {
                    setLoader(false)
                    toast({
                        title: 'Company updated.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    navigate("/companies");
                } else {
                    setLoader(false)
                    toast({
                        title: 'Error with saving.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }

            })
            reader.readAsDataURL(e.target.img.files[0])
        } else {
            setLoader(true)
            let res = await resources.company.update(id, {
                name: e.target.name.value,
                description: e.target.description.value
            })

            if (res) {
                setLoader(false)
                toast({
                    title: 'Company updated.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                navigate("/companies");
            } else {
                setLoader(false)
                toast({
                    title: 'Error with saving.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }

        setLoader(false)
    }

    useEffect(() => {
        read()
    }, [])


    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Materials</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/companies">Materials</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">update material</li>
                            </ol>
                        </nav>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='card'>

                                <div className='card-body'>
                                    <form onSubmit={update} >
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Image </label>
                                            <input type="file" className="form-control" id="exampleInputEmail1"
                                                name="img" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Name<span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="name" defaultValue={data?.name} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Description <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                name="description" defaultValue={data?.description} />
                                        </div>
                                        <Button type="submit" leftIcon={<FiSave />} isLoading={loader} colorScheme='brand' className="w-100">Save</Button>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
