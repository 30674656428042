import React, { useState, useEffect, useRef } from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import Icon from '../../ui/Icon';
import { useReactToPrint } from 'react-to-print';
import resources from "../../api";
import config from '../../constants';
import Select from 'react-select'
import DataReport from '../../components/DataReport';
import { ComponentToPrint } from '../../components/ComponentToPrint';
import Material from '../../components/Material';
import { useDownloadExcel } from 'react-export-table-to-excel';
import {
    Button, ButtonGroup, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import {
    FiPlusCircle,
    FiFilter,
    FiChevronDown
} from 'react-icons/fi';

import {
    ImFileExcel,
    ImFilePdf
} from 'react-icons/im';


export default function Index() {
    const [filter, setFilter] = useState(false)
    const [companies, setCompanies] = useState([])
    const [bins, setbins] = useState([])
    const [storages, setStorages] = useState([])
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])
    const [data, setData] = useState([])


    const componentRef = useRef();
    const componentRef1 = useRef();
    const tableRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrint1 = useReactToPrint({
        content: () => componentRef1.current,
    });


    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Report',
        sheet: 'Matrials'
    })


    function getBase64(e) {

        const reader = new FileReader();
        reader.addEventListener('load', () => {
            console.log(reader.result)

        })
        reader.readAsBinaryString(e)
    }

    function getBase64Image(src, callback) {
        var image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            context.drawImage(this, 0, 0);
            var dataURL = canvas.toDataURL('image/jpeg');
            callback(dataURL);
        };
        image.src = src;
    }

    const ImgeRender = (el) => {
        return <p>{el}</p>
    }


    async function onGet() {

        let com = await resources.company.read(10000)
        if (com.status === 200) {
            let a = []
            com.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompanies(a)
        }

        let bin = await resources.bin.read(10000)
        if (bin.status === 200) {
            let a = []
            bin.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setbins(a)
        }

        let stor = await resources.storage.read(10000)
        if (stor.status === 200) {
            let a = []
            stor.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setStorages(a)
        }

        let typ = await resources.type.read(10000)
        if (typ.status === 200) {
            let a = []
            typ.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setTypes(a)
        }

        let sub = await resources.subtype.read(10000)
        if (sub.status === 200) {
            let a = []
            sub.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setSubtypes(a)
        }


    }


    async function createReport(e) {
        e.preventDefault()
        setFilter(true)
        let formData = new FormData(e.target);
        let el = '';

        if (formData.get('from')) {
            el += "from=" + formData.get('from')
        }
        if (formData.get('to')) {
            el += "&to=" + formData.get('to')
        }

        if (formData.get('type')) {
            el += "&type=" + formData.get('type')
        }
        if (formData.get('company')) {
            el += "&company=" + formData.get('company')
        }
        let res = await resources.report.create(el)
        if (res.status === 200) {
            setData(res.data)
            setFilter(false)
        }
        setFilter(false)
    }

    useEffect(() => {
        onGet();
    }, [])

    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Report</h3>
                    <div className="card card-body mb-3 mt-4">
                        <form onSubmit={createReport} >
                            <div className="row">
                                <div className="col">
                                    <input type="date" class="form-control" id="exampleFormControlInput1" placeholder="From" name="from" />
                                </div>
                                <div className="col">
                                    <input type="date" class="form-control" id="exampleFormControlInput1" placeholder="To" name="to" />
                                </div>

                                {
                                    localStorage.getItem("userRole") === "CLIENT" ?
                                        <input type="hidden" class="form-control" id="exampleFormControlInput1" name="company" defaultValue={localStorage.getItem("userCompany")} />
                                        :
                                        <div className="col">
                                            <Select name="company" placeholder="Company" options={companies} />
                                        </div>}


                                <div className="col">
                                    <Select name="type" placeholder="Type" options={types} />
                                </div>


                                <div className="col">
                                    <Button isLoading={filter} className="w-100" colorScheme='brand' type="submit"> Apply</Button>
                                </div>


                                <div className="col">
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<FiChevronDown />}>
                                            Print options
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem icon={<ImFilePdf />} onClick={handlePrint}>
                                                All list
                                            </MenuItem>
                                            <MenuItem icon={<ImFilePdf />} onClick={handlePrint1}>
                                                Detailed list
                                            </MenuItem>
                                            <MenuItem icon={<ImFileExcel />} onClick={onDownload}>
                                                Save to excel format
                                            </MenuItem>

                                        </MenuList>
                                    </Menu>

                            
                                </div>

                            </div>

                        </form>

                    </div>




                    <ComponentToPrint ref={componentRef}>
                        <DataReport

                            column={[
                                {
                                    label: "Cover ", field: "picture", renderStyle: (data) => {
                                        return <img src={config.server + "/files/" + data.cover} alt={data.name} width="80" />
                                    }
                                },
                                { label: "Material number ", field: "unique_number" },
                                { label: "Company ", field: "company", render: ["name"] },
                                { label: "Name", field: "name" },
                                { label: "Stock on hand", field: "stock_on_hand" },
                                { label: "Type", field: "type", render: ["name"] },
                                { label: "Subtype", field: "sub_type", render: ["name"] },
                                { label: "Storage location", field: "storage", render: ["name"] },
                                { label: "Bin location", field: "bin", render: ["name"] },
                                { label: "Where used", field: "where_used" },
                                { label: "Set size", field: "set_size" },
                                { label: "OEM", field: "oem" },
                                { label: "Last purchased from", field: "last_purshased_from" },
                                { label: "Last purchased date", field: "last_purshased_date" },
                                { label: "Material part number", field: "part_number" },
                                { label: "Description", field: "description" },
                            ]}
                            data={data}
                        />
                    </ComponentToPrint>







                    <div style={{ display: "none" }}>


                        <table ref={tableRef}>
                            <tbody>
                                <tr class="table-info">
                                    <th>Cover </th>
                                    <th>Material number </th>
                                    <th>Material part number</th>
                                    <th>Company</th>
                                    <th>Stock on hand</th>
                                    <th>Type</th>
                                    <th>Subtype</th>
                                    <th>Storage location</th>
                                    <th>Bin location</th>
                                    <th>Where used</th>
                                    <th>Set size</th>
                                    <th>OEM</th>
                                    <th>Last purchased from</th>
                                    <th>Last purchased date</th>
                                    <th>Description</th>
                                </tr>

                                {
                                    data && data.map((el, key) => {


                                        return (
                                            <tr key={key}>
                                                <td>
                                                    {config.server + "/files/" + el.cover}
                                                </td>
                                                <td>{el?.unique_number}</td>
                                                <td>{el?.part_number}</td>
                                                <td>{el?.company?.name}</td>
                                                <td>{el?.stock_on_hand}</td>
                                                <td>{el?.type?.name}</td>
                                                <td>{el?.sub_type?.name}</td>
                                                <td>{el?.storage?.name}</td>
                                                <td>{el?.bin?.name}</td>
                                                <td>{el?.where_used}</td>
                                                <td>{el?.set_size}</td>
                                                <td>{el?.oem}</td>
                                                <td>{el?.last_purshased_from}</td>
                                                <td>{el?.last_purshased_date}</td>
                                                <td>{el?.description}</td>
                                            </tr>
                                        )

                                    })


                                }




                            </tbody>
                        </table>
                        <ComponentToPrint ref={componentRef1}>
                            {
                                data && data.map((el, key) => {
                                    return <Material data={el} key={key} />
                                })
                            }
                        </ComponentToPrint>
                    </div>



                </div>
            </div>

        </div>
    )
}
