import React from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import { Button, ButtonGroup } from '@chakra-ui/react'
import {
    FiPlusCircle
} from 'react-icons/fi';

export default function index() {
    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Storage location</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item active" aria-current="page">Storage location</li>
                            </ol>
                        </nav>
                        <Link className="btn btn-app-default " to="/storage/create"><Button leftIcon={<FiPlusCircle />} colorScheme='brand'>New</Button></Link>
                    </div>

                    <DataTable column={[

                        { label: "Name", field: "name" },
                        { label: "Description", field: "description" }
                    ]}

                        element={"storage"}
                        resource={resources.storage}
                        filterOption={false}
                        filterLoader={() => { }}
                        action={(data) => {

                        }}
                        deleteItem
                        update
                    />
                </div>
            </div>

        </div>
    )
}
