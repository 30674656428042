import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import resources from '../../api';
import Select from 'react-select'
import Creatable from 'react-select/creatable';
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import {
    FiSave
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";


export default function Create() {
    const [loader, setLoader] = useState(false)
    const [companies, setCompanies] = useState([])
    const [bins, setbins] = useState([])
    const [storages, setStorages] = useState([])
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])
    const toast = useToast()
    const navigate = useNavigate();

    async function onGet() {
        let com = await resources.company.read(10000)
        if (com.status === 200) {
            let a = []
            com.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompanies(a)
        }

        let bin = await resources.bin.read(10000)
        if (bin.status === 200) {
            let a = []
            bin.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setbins(a)
        }

        let stor = await resources.storage.read(10000)
        if (stor.status === 200) {
            let a = []
            stor.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setStorages(a)
        }

        let typ = await resources.type.read(10000)
        if (typ.status === 200) {
            let a = []
            typ.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setTypes(a)
        }

        let sub = await resources.subtype.read(10000)
        if (sub.status === 200) {
            let a = []
            sub.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setSubtypes(a)
        }

    }

    useEffect(() => {
        onGet();
    }, [])


    function create(e) {
        e.preventDefault()
        setLoader(true)
        const reader = new FileReader();
        reader.addEventListener('load', () => {

            let formData = new FormData(e.target)
            if (formData.get("cover")) {
                formData.delete("cover")
            }

            if (localStorage.getItem("userRole") === "CLIENT") {
                formData.append(localStorage.getItem("userCompany"))
            }
            console.log(reader.result)
            let object = {};
            formData.forEach((value, key) => object[key] = value);
            object.cover = reader.result

            let res = resources.material.create(object, localStorage.getItem("userToken"))

            if (res) {
                setLoader(false)
                toast({
                    title: 'Material created.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
                navigate("/materials");
            } else {
                setLoader(false)
                toast({
                    title: 'Error with saving.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }

        })
        reader.readAsDataURL(e.target.cover.files[0])
        setLoader(false)

    }

    return (
        <div>
          
                <div className="row">
                    <div className="col">
                        <h3 className='mt-3'>Materials</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page"><Link
                                        className="text-muted" to="/materials">Materials</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">new material</li>
                                </ol>
                            </nav>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-lg-6'>
                                <div className='card'>

                                    <div className='card-body'>
                                        <form onSubmit={create}>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Image <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="file" required className="form-control" id="exampleInputEmail1"
                                                    name="cover" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Name <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="text" required className="form-control" id="exampleInputEmail1"
                                                    name="name" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Material number</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                    name="unique_number" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Material part number</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                    name="part_number" />
                                            </div>

                                            {
                                                localStorage.getItem("userRole") != "CLIENT" &&
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                        className="form-label">Company <span
                                                            style={{ color: "red" }}>*</span></label>
                                                    <Select required name="company" options={companies} />
                                                </div>}

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                    className="form-label">Type </label>
                                                <Select name="type" options={types} />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                    className="form-label">Subtype </label>
                                                <Select name="sub_type" options={subtypes} />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                    className="form-label">Storage location</label>
                                                <Select name="storage" options={storages} />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1"
                                                    className="form-label">Bin location</label>
                                                <Creatable name="bin"  options={bins} />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Stock on hand </label>
                                                <input type="number" className="form-control" id="exampleInputEmail1"
                                                    name="stock_on_hand" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Last purchased date</label>
                                                <input type="date" className="form-control" id="exampleInputEmail1"
                                                    name="last_purshased_date" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Last purchased from </label>
                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                    name="last_purshased_from" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Set size</label>
                                                <input type="text"  className="form-control" id="exampleInputEmail1"
                                                    name="set_size" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">OEM</label>
                                                <input type="text"  className="form-control" id="exampleInputEmail1"
                                                    name="oem" />
                                            </div>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Where used</label>
                                                <input type="text"  className="form-control" id="exampleInputEmail1"
                                                    name="where_used" />
                                            </div>


                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlTextarea1"
                                                    className="form-label">Description <span
                                                        style={{ color: "red" }}>*</span></label>
                                                <textarea className="form-control" required id="exampleFormControlTextarea1"
                                                    rows="3" name="description" />
                                            </div>

                                            <Button type="submit" leftIcon={<FiSave />} isLoading={loader} colorScheme='brand' className="w-100">Save</Button>
                                        </form>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
        
        </div>
    )
}
