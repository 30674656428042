import React, { useState, useEffect } from 'react'
import {
    Link
} from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../api";
import config from '../../constants';
import Select from 'react-select'
import { Button, ButtonGroup } from '@chakra-ui/react'
import {
    FiPlusCircle,
    FiFilter
} from 'react-icons/fi';


export default function Material() {

    const [filter, setFilter] = useState(false)
    const [filterOption, setFilterOption] = useState(false)
    const [companies, setCompanies] = useState([])
    const [bins, setbins] = useState([])
    const [storages, setStorages] = useState([])
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])



    async function onGet() {

        let com = await resources.company.read(10000)
        if (com.status === 200) {
            let a = []
            com.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompanies(a)
        }

        let bin = await resources.bin.read(10000)
        if (bin.status === 200) {
            let a = []
            bin.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setbins(a)
        }

        let stor = await resources.storage.read(10000)
        if (stor.status === 200) {
            let a = []
            stor.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setStorages(a)
        }

        let typ = await resources.type.read(10000)
        if (typ.status === 200) {
            let a = []
            typ.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setTypes(a)
        }

        let sub = await resources.subtype.read(10000)
        if (sub.status === 200) {
            let a = []
            sub.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setSubtypes(a)
        }


    }

    useEffect(() => {
        onGet();
    }, [])

    function filerArticle(e) {
        e.preventDefault();
        setFilter(true)
        let formData = new FormData(e.target);
        let el = '';
        if (formData.get('name')) {
            el = "&name=" + formData.get('name')
        }
        if (formData.get('unique_number')) {
            el += "&unique_number=" + formData.get('unique_number')
        }

        if (formData.get('type')) {
            el += "&type=" + formData.get('type')
        }
        if (formData.get('stock_on_hand')) {
            el += "&stock_on_hand=" + formData.get('stock_on_hand')
        }

        window.location.replace("/materials?" + el)

    }

    function onChangeFilterLoader() {
        setFilter(false)
    }

    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Materials</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">

                                <li className="breadcrumb-item active" aria-current="page">Materials</li>
                            </ol>
                        </nav>
                        <div className="d-flex ">

                            <ButtonGroup gap='2'>
                                {
                                    filterOption ? <Button leftIcon={<FiFilter />}  colorScheme='red' onClick={() => setFilterOption((e) => !e)} >Close filter</Button>
                                        : <Button leftIcon={<FiFilter />}  colorScheme='green' onClick={() => setFilterOption((e) => !e)} > Open filter</Button>
                                }

                                <Link to="/material/create"><Button leftIcon={<FiPlusCircle />} colorScheme='brand'>New</Button></Link>
                            </ButtonGroup>
                        </div>

                    </div>

                    <div className="card card-body mb-3 mt-4">
                        <form onSubmit={filerArticle}>
                            <div className="row">
                                <div className="col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Material number" name="unique_number" />
                                </div>
                                <div className="col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" name="name" />
                                </div>


                                {
                                    localStorage.getItem("userRole") === "CLIENT" && <input type="hidden" class="form-control" id="exampleFormControlInput1" name="company" defaultValue={localStorage.getItem("userCompany")} />
                                }

                                <div className="col">
                                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Stock on hand" name="stock_on_hand" />
                                </div>
                                <div className="col">
                                    <Select name="type" placeholder="Type" options={types} />
                                </div>


                                <div className="col">
                                    <Button isLoading={filter} className="w-100" colorScheme='brand'  type="submit"> Apply</Button>
                                </div>

                            </div>

                        </form>

                    </div>

                    <DataTable column={[
                        { label: "Material number", field: "unique_number", },
                        { label: "Name", field: "name" },
                        {
                            label: "Cover ", field: "cover", renderStyle: (data) => {
                                return <img src={config.server + "/files/" + data.cover} alt={" "} width="80" />
                            },
                            filterOptions: {
                                actif: true,
                                rules: "exist",
                                value: [{ label: "Empty", value: false }, { label: "Filled", value: true }]
                            }

                        },

                        { label: "Company ", field: "company", render: ["name"] },

                        { label: "Stock count", field: "stock_on_hand" },
                        { label: "Type", field: "type", render: ["name"] },
                        { label: "Sub-type", field: "sub_type", render: ["name"] },
                        { label: "Storage location", field: "storage", render: ["name"] },
                        { label: "Bin location", field: "bin", render: ["name"] },
                        { label: "Where used", field: "where_used" },
                        { label: "Set size", field: "set_size" },
                        { label: "OEM", field: "oem" },
                        { label: "Last purchased from", field: "last_purshased_from" },
                        { label: "Last purchased date", field: "last_purshased_date" },
                        { label: "Material part number", field: "part_number" },
                        { label: "Description", field: "description" },
                    ]}

                        filterOption={filterOption}
                        filterLoader={onChangeFilterLoader}
                        element={"material"}
                        resource={resources.material}
                        action={(data) => {

                        }}
                        update
                        deleteItem
                        view
                    />
                </div>
            </div>

        </div>
    )
}
