import React, { useState, useEffect, useRef } from 'react'
import { QRCodeSVG } from 'qrcode.react';
import Barcode from 'react-barcode';
import config from '../constants';

export default function Material({ data, code }) {
    const [images, setImage] = useState([])
    useEffect(() => {
        let a = []
        data && data?.images && data?.images.forEach((el) => {
            a.push(el)
        })
        a.push(data.cover)
        setImage(a)
    }, [data, code])

    function sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    return (
        <div className='card'>
            <div class="card-header ">
                <div className='d-flex justify-content-between p-5'>
                    <div>
                        <img src={config.server + "/files/" + data?.company?.logo} alt={data?.company?.name} width="100" />
                    </div>
                    <div>
                        {
                            code == 0 ? <QRCodeSVG value={"https://bfm-picco.com/single/" + data?.id} size={100} /> : <Barcode displayValue={false} background={"none"} value={data?.id} size={50} />
                        }
                        ,
                    </div>
                </div>

            </div>


            <div className='card-body'>

                <table class="table table-borderless">
                    <tbody>


                        {
                            images && sliceIntoChunks(images, 3).map((el, key) => {
                                return <tr key={key}>

                                    {
                                        el.map((k, i) => {
                                            return <td key={i}>

                                                <img src={config.server + "/files/" + k + "?x=0"} width="300" />


                                            </td>
                                        })
                                    }
                                </tr>

                            })
                        }

                    </tbody>

                </table>
            </div>


            <ol class="list-group list-group-numbered list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Material name</div>
                        {data?.name}
                    </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Material number</div>
                        {data?.unique_number}
                    </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Material part number</div>
                        {data?.part_number}
                    </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Material type</div>
                        {data?.type?.name}
                    </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Material Subtype</div>
                        {data?.sub_type?.name}
                    </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Material storage location</div>
                        {data?.storage?.name}
                    </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Material bin location</div>
                        {data?.bin?.name}
                    </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Stock on hand </div>
                        {data?.stock_on_hand}
                    </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Last purchased date </div>
                        {data?.last_purshased_date}
                    </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Last purchased from </div>
                        {data?.last_purshased_from}
                    </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Set size </div>
                        {data?.set_size}
                    </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">OEM </div>
                        {data?.oem}
                    </div>
                </li>

                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Where used </div>
                        {data?.where_used}
                    </div>
                </li>


                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                        <div class="fw-bold">Description</div>
                        {data?.description}
                    </div>
                </li>
            </ol>





        </div>
    )
}
