import Axios from "../Axios";

const material = {

    readOne(id: string) {
        return Axios.get('/materials/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    read(limit: number, page: number, rule: null) {
        return Axios.get('/materials?limit=' + limit + "&page=" + page + "&" + rule, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object) {
        return Axios.post('/materials', data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    addImage(data: object, id: string) {
        return Axios.put('/materials/' + id + "/img", data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },

    removeImage(id: string, el: string) {
        return Axios.delete('/materials/' + id + "/remove/" + el, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    put(data: object, id: string) {
        return Axios.put('/materials/' + id, data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    delete(id: string) {
        return Axios.delete('/materials/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
}

export default material;
