import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import {
    Link, useParams
} from "react-router-dom";
import resources from '../../api';
import Select from 'react-select'
import config from '../../constants';
import { Field } from '../../components/Field';
import { ApiErrors } from "../../components/api"
import {
    Button, ButtonGroup, useToast, useDisclosure, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input
} from '@chakra-ui/react'

import {
    FiSave,
    FiImage
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";


export default function Edit() {
    const [data, setData] = useState({})
    const [loader, setLoader] = useState(false)
    const [companies, setCompanies] = useState([])
    const [bins, setbins] = useState([])
    const [storages, setStorages] = useState([])
    const [types, setTypes] = useState([])
    const [subtypes, setSubtypes] = useState([])
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false)
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()

    async function onGet() {

        let mat = await resources.material.readOne(id)
        if (mat.status === 200) {
            setData(mat.data)
        }

        let com = await resources.company.read(10000)
        if (com.status === 200) {
            let a = []
            com.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompanies(a)
        }

        let bin = await resources.bin.read(10000)
        if (bin.status === 200) {
            let a = []
            bin.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setbins(a)
        }

        let stor = await resources.storage.read(10000)
        if (stor.status === 200) {
            let a = []
            stor.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setStorages(a)
        }

        let typ = await resources.type.read(10000)
        if (typ.status === 200) {
            let a = []
            typ.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setTypes(a)
        }

        let sub = await resources.subtype.read(10000)
        if (sub.status === 200) {
            let a = []
            sub.data.docs.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setSubtypes(a)
        }

    }

    useEffect(() => {
        onGet();
    }, [])


    function addImage(e) {
        setLoading(true)
        e.preventDefault()
        const reader = new FileReader();
        reader.addEventListener('load', () => {

            let object = {
                img: reader.result
            };
            setLoading(true)

            let res = resources.material.addImage(object, id)

            if (res) {
                onGet()
                setLoading(false)
                toast({
                    title: 'Imave added.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                setLoading(false)
                toast({
                    title: 'Error occured.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }


        })
        reader.readAsDataURL(e.target.cover.files[0])

    }


    async function removeImage(params) {

        try {
            let res = await resources.material.removeImage(id, params)
            if (res.status == 200) {
                onGet()
                onClose()
                toast({
                    title: 'Imave removed.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            }

        } catch (error) {

            toast({
                title: 'Error occured.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }

    }

    async function create(e) {
        e.preventDefault()

        let formData = new FormData(e.target)
        if (formData.get("set_size") == "0") {
            formData.delete("set_size")
        }
        if (e.target.cover.files[0]) {
            formData.delete("cover")
            setLoader(true)
            const reader = new FileReader();
            reader.addEventListener('load', async () => {

                setLoader(true)


                let object = {};
                formData.forEach((value, key) => object[key] = value);
                object.cover = reader.result

                try {
                    let res = await resources.material.put(object, id, localStorage.getItem("userToken"))



                    if (res) {
                        setErrors({});
                        setSuccess(true)
                        setLoader(false)
                        toast({
                            title: 'Material updated.',
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                        })
                        navigate("/materials");
                    }

                } catch (responseData) {
                    setErrors({});
                    const e = new ApiErrors(responseData.response.data.errors);
                    console.log(e)
                    setErrors(e.errorsPerField);
                    setLoader(false)
                    toast({
                        title: 'Error with saving.',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }


            })
            reader.readAsDataURL(e.target.cover.files[0])

        } else {
            setLoader(true)
            formData.delete("cover")
            let object = {};
            formData.forEach((value, key) => object[key] = value);

            try {
                let res = await resources.material.put(object, id, localStorage.getItem("userToken"))

                if (res) {
                    setErrors({});
                    setSuccess(true)
                    setLoader(false)
                    toast({
                        title: 'Material updated.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    navigate("/materials");
                }
            } catch (responseData) {
                setErrors({});
                const e = new ApiErrors(responseData.response.data.errors);
                console.log(e)
                setErrors(e.errorsPerField);
                setLoader(false)
                toast({
                    title: 'Error with saving.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }

        }

    }


    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Materials</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/materials">Materials</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">update material</li>
                            </ol>
                        </nav>

                        <Button onClick={onOpen} leftIcon={<FiImage />} colorScheme='brand' >Add photo</Button>




                        <Modal

                            isOpen={isOpen}
                            onClose={onClose}
                        >
                            <ModalOverlay />
                            <ModalContent>
                                <form onSubmit={addImage}>
                                    <ModalHeader>Add new image</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody pb={6}>

                                        <FormControl>
                                            <FormLabel>Image</FormLabel>
                                            <Input type="file" name="cover" />
                                        </FormControl>

                                    </ModalBody>

                                    <ModalFooter>
                                        <Button type="submit" leftIcon={<FiSave />} isLoading={loading} colorScheme='brand' mr={3} >Save</Button>
                                        <Button onClick={onClose}>Cancel</Button>
                                    </ModalFooter>
                                </form>
                            </ModalContent>
                        </Modal>








                        {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">

                                    <form onSubmit={addImage}>

                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="exampleModalLabel">Add more photo</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Image </label>
                                                <input type="file" className="form-control" id="exampleInputEmail1"
                                                    name="cover" />
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <Button type="submit" leftIcon={<FiSave />} isLoading={loader} colorScheme='brand' >Save</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> */}








                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>

                            <div className='row'>

                                <div className='col-lg-4 mb-1' >
                                    <div class="card">
                                        <img src={config.server + "/files/" + data.cover} class="card-img-top" alt="..." width={100} />

                                    </div>
                                </div>

                                {
                                    data && data?.images && data?.images.map((el, key) => {
                                        return <div className='col-lg-4 mb-1' key={key}>
                                            <div class="card">
                                                <img src={config.server + "/files/" + el} class="card-img-top" alt="..." width={100} />
                                                <div class="card-body">
                                                    <button onClick={() => removeImage(el)} class="btn btn-danger">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }

                            </div>
                            <div className='card mt-3'>

                                <div className='card-body'>
                                    <form onSubmit={create}>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Image </label>
                                            <input type="file" className="form-control" id="exampleInputEmail1"
                                                name="cover" />
                                        </div>

                                        <div className="mb-3">
                                            <Field name="name" required error={errors?.name} defaultValue={data?.name}>
                                                Name
                                            </Field>
                                        </div>

                                        <div className="mb-3">

                                            <Field name="unique_number" error={errors?.unique_number} defaultValue={data?.unique_number}>
                                                Material number
                                            </Field>

                                        </div>

                                        <div className="mb-3">

                                            <Field name="part_number" error={errors?.part_number} defaultValue={data?.part_number}>
                                                Material part number
                                            </Field>

                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Company </label>
                                            <Select name="company" options={companies} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Type </label>
                                            <Select name="type" options={types} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Subtype </label>
                                            <Select name="sub_type" options={subtypes} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Storage location</label>
                                            <Select name="storage" options={storages} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Bin location</label>
                                            <Select name="bin" options={bins} />
                                        </div>

                                        <div className="mb-3">

                                            <Field name="stock_on_hand" error={errors?.stock_on_hand} defaultValue={data?.stock_on_hand}>
                                                Stock on hand
                                            </Field>
                                        </div>

                                        <div className="mb-3">

                                            <Field type="date" name="last_purshased_date" error={errors?.last_purshased_date} defaultValue={data?.last_purshased_date}>
                                                Last purchased date
                                            </Field>
                                        </div>

                                        <div className="mb-3">

                                            <Field name="last_purshased_from" error={errors?.last_purshased_from} defaultValue={data?.last_purshased_from}>
                                                Last purchased from
                                            </Field>


                                        </div>
                                        <div className="mb-3">

                                            <Field name="set_size" error={errors?.set_size} defaultValue={data?.set_size}>
                                                Set size
                                            </Field>

                                        </div>

                                        <div className="mb-3">

                                            <Field name="oem" error={errors?.oem} defaultValue={data?.oem}>
                                                OEM
                                            </Field>


                                        </div>

                                        <div className="mb-3">

                                            <Field name="oem" error={errors?.where_used} defaultValue={data?.where_used}>
                                                Where used
                                            </Field>
                                        </div>


                                        <div className="mb-3">

                                            <Field type="textarea" name="description" rows="3" error={errors?.description} defaultValue={data?.description}>
                                                Description
                                            </Field>


                                        </div>

                                        <Button type="submit" leftIcon={<FiSave />} isLoading={loader} colorScheme='brand' className="w-100">Save</Button>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
