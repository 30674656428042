import Axios from "../Axios";

const report = {
    create(data: string) {
        return Axios.get('/repports?' + data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    }
}

export default report;
