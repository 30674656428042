import Axios from "../Axios";

const storage = {
    read(limit: number, page: number, rule: null) {
        return Axios.get('/storages?limit=' + limit + "&page=" + page + rule, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    readOne(id: string) {
        return Axios.get('/storages/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    create(data: object, token: string) {
        return Axios.post('/storages', data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    },
    update(id: string, data: object) {
        return Axios.put('/storages/' + id, data, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },
    delete(id: string) {
        return Axios.delete('/storages/' + id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("userToken")
            }
        })
    },


}

export default storage;
