import React, { ReactNode } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Divider,
    Image
} from '@chakra-ui/react';
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
    FiUsers,
    FiUser,
    FiChevronDown,
    FiBriefcase
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import logo from "../assets/picco.png";
import { Link, Path } from 'react-router-dom';

interface LinkItemProps {
    name: string;
    icon: IconType;
    link: string;
}
const LinkItems: Array<LinkItemProps> = [
    { name: 'Materials', icon: FiHome, link: "/materials" },
    { name: 'Company', icon: FiBriefcase, link: "/companies" },
    { name: 'Report', icon: FiTrendingUp, link: "/report" },
    { name: 'User', icon: FiUsers, link: "/users" },
];

const LinkItems2: Array<LinkItemProps> = [
    { name: 'Types', icon: FiSettings, link: "/types" },
    { name: 'Subtypes', icon: FiSettings, link: "/subtypes" },
    { name: 'Storage location', icon: FiSettings, link: "/storages" },
    { name: 'Bin location', icon: FiSettings, link: "/bins" },
];

export default function SidebarWithHeader({
    children,
}: {
    children: ReactNode;
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} >
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
            >
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0 }} p="4">
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
        <Box
            transition="3s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full' }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">

                </Text>
                <CloseButton display={{ base: 'flex' }} onClick={onClose} />
            </Flex>
            {LinkItems.map((link) => (
                <NavItem key={link.name} link={link.link} icon={link.icon}>
                    {link.name}
                </NavItem>
            ))}

            <Divider />

            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"

            >
                <h5>Settings</h5>
            </Flex>
            {LinkItems2.map((link) => (
                <NavItem key={link.name} icon={link.icon} link={link.link}>
                    {link.name}
                </NavItem>
            ))}



        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    link: Partial<string>;
    children: ReactText;
}
const NavItem = ({ icon, children, link, ...rest }: NavItemProps) => {
    return (
        <Link to={link} style={{ textDecoration: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'brand.500',
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    return (
        <Flex


            ml={{ base: 0 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Link to="/materials">
                <Text
                    display={{ base: 'flex' }}
                    fontSize="2xl"
                    fontFamily="monospace"
                    fontWeight="bold">
                    <Image src={logo} width={100} alt='Dan Abramov' />
                </Text></Link>

            <HStack spacing={{ base: '0', md: '6' }}>

                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar bg='brand.500' icon={<FiUser fontSize='1.5rem' />} />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">{localStorage.getItem("userName")}</Text>
                                    <Text fontSize="xs" color="gray.600">
                                        {localStorage.getItem("userRole")}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            {/* <MenuItem>Profile</MenuItem>
                            <MenuItem>Settings</MenuItem>
                            <MenuItem>Billing</MenuItem>
                            <MenuDivider /> */}
                            <MenuItem>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};
