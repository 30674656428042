import React, { useState } from 'react'
import Header from '../../components/Header'
import {
    Link
} from "react-router-dom";
import resources from '../../api';
import { Button, ButtonGroup, useToast } from '@chakra-ui/react'
import {
    FiSave
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";

export default function Create() {
    const [loader, setLoader] = useState(false)
    const toast = useToast()
    const navigate = useNavigate();

    function create(e) {
        e.preventDefault()
        setLoader(true)
        let formData = new FormData(e.target)
        let res = resources.type.create(formData, localStorage.getItem("userToken"))
        if (res) {
            setLoader(false)
            toast({
                title: 'Type created.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            navigate("/types");
        } else {
            setLoader(false)
            toast({
                title: 'Error with saving.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }


    }
    return (
        <div>
         
                <div className="row">
                    <div className="col">
                        <h3 className='mt-3'>Type</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page"><Link
                                        className="text-muted" to="/types">Types</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">new type</li>
                                </ol>
                            </nav>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-lg-6'>
                                <div className='card'>

                                    <div className='card-body'>
                                        <form onSubmit={create}>

                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Name <span
                                                    style={{ color: "red" }}>*</span></label>
                                                <input type="text" required className="form-control" id="exampleInputEmail1"
                                                    name="name" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Description</label>
                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                    name="description" />
                                            </div>
                                            <Button type="submit" leftIcon={<FiSave />} isLoading={loader} colorScheme='brand' className="w-100">Save</Button>
                                        </form>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
          
        </div>
    )
}
