import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import Icon from "../ui/Icon";
import Select from 'react-select'
import {
    Button, ButtonGroup, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    IconButton
} from '@chakra-ui/react'
import {
    ImFileExcel,
    ImFilePdf
} from 'react-icons/im';
import {
    FiEye,
    FiMoreVertical,
    FiRefreshCcw,
    FiTrash2
} from 'react-icons/fi';
import { useNavigate } from "react-router-dom";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useToast
} from '@chakra-ui/react'



export default function DataTable({ resource, column, deleteItem, update, action, element, view, filterLoader, filterOption }) {

    let text = window.location.href;
    const myArray = text.split("?");
    const [data, setData] = useState([]),
        [memorie, setMemorie] = useState([]),
        [detail, setDetail] = useState({}),
        [open, setOpen] = useState(false),
        [item, setItem] = useState(),
        [limit, setLimit] = useState(50),
        [page, setPage] = useState(1),
        [pages, setPages] = useState([]),
        [loader, setLoader] = useState(true),
        [entries, setEntries] = useState(0),
        [currentPage, setCurrentPage] = useState(1)
    const toast = useToast()


    const { isOpen, onOpen, onClose } = useDisclosure()

    const navigate = useNavigate();

    const handleClickOpen = (id) => {
        onOpen()
        setItem(id)
    };




    async function onGet() {

        setLoader(true)
        let res = await resource.read(limit, page, myArray[1] && myArray[1])
        if (res.status === 200) {
            setData(res.data.docs)
            setMemorie(res.data.docs)

            setEntries(res.data.totalDocs)
            setDetail(res.data)
            let a = []
            for (let index = 1; index < res.data.totalPages; index++) {
                a.push(index)

            }
            setPages(a)
            setCurrentPage(res.data.page)
            setLoader(false)
            filterLoader()
        }
        setLoader(false)
        filterLoader()
    }


    async function onDelete() {
        let res = await resource.delete(item)
        if (res) {
            onGet()
            toast({
                title: 'Item deleted.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            onClose()
        } else {
            toast({
                title: 'Error occured.',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            onClose()
        }
    }

    function handleFilterWithOptions(value, options, field) {

        if (options.rules == "exist") {

            let a = []
            let b = []
            memorie.forEach(el => {

                if (field in el) {
                    a.push(el)
                } else {
                    b.push(el)
                }

            })


            if (value.value) {
                setData(a)
            } else {
                setData(b)
            }


        }



    }

    useEffect(() => {
        onGet()
    }, [limit, page, myArray[1], filterOption])

    return (
        <div>


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Warning</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Do you want to delete item?
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='brand' mr={3} onClick={onDelete}>
                            Yes
                        </Button>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>

                        {
                            filterOption ?
                                <tr>
                                    <th scope="col">Action</th>
                                    {column && column.map((i, k) => {

                                        let a = []
                                        data.forEach(element => {
                                            a.push({
                                                label: element[i.field],
                                                value: element[i.field]
                                            })
                                        });

                                        if (i?.filterOptions?.actif) {
                                            if (i.filterOptions) {
                                                return <th scope="col" key={k}>

                                                    <Select name={i.label} options={i.filterOptions.value} onChange={(e) => handleFilterWithOptions(e, i.filterOptions, i.field)} />
                                                </th>
                                            } else {
                                                return <th scope="col" key={k}>

                                                    <Select name={i.label} options={a} />
                                                </th>
                                            }
                                        } else {
                                            return <th scope="col" key={k}>
                                                {i.label}
                                            </th>
                                        }



                                    })}

                                </tr>
                                :
                                <tr>
                                    <th scope="col">Action</th>
                                    {column && column.map((i, k) => {


                                        return <th scope="col" key={k}>
                                            {i.label}
                                        </th>
                                    })}

                                </tr>
                        }

                    </thead>
                    <tbody>
                        {
                            data && data.map((el, key) => {
                                return <tr className="bg-white" key={key}>

                                    {
                                        action && (
                                            <td>


                                                <Menu>
                                                    <MenuButton
                                                        as={IconButton}
                                                        aria-label='Options'
                                                        icon={<FiMoreVertical />}
                                                        variant='contained'
                                                    />
                                                    <MenuList>

                                                        {
                                                            view && (<MenuItem icon={<FiEye />} onClick={() => navigate("/" + element + "/view/" + el.id)} >
                                                                View
                                                            </MenuItem>)
                                                        }

                                                        {
                                                            update && (<MenuItem icon={<FiRefreshCcw />} onClick={() => navigate("/" + element + "/edit/" + el.id)} >
                                                                Update
                                                            </MenuItem>)
                                                        }

                                                        {
                                                            deleteItem && (
                                                                <MenuItem icon={<FiTrash2 />} onClick={() => handleClickOpen(el.id)}>
                                                                    Delete
                                                                </MenuItem>)
                                                        }
                                                        {action(el, onGet) && action(el, onGet)}

                                                    </MenuList>
                                                </Menu>



                                            </td>
                                        )
                                    }

                                    {column && column.map((i) => {
                                        return <td>{!i.renderStyle ? !i.render ? el.hasOwnProperty(i.field) && el[i.field] : i.render.map((a, key) => {
                                            return el.hasOwnProperty(i.field) && el[i.field] ? el[i.field][a] : null
                                        }) : i.renderStyle(el)}</td>
                                    })}

                                </tr>
                            })
                        }

                    </tbody>
                </table>
            </div>

            {loader && <div className="row justify-content-center">
                <div className="col-lg-1">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>}



            <div className="d-flex justify-content-between">
                <div >
                    <select className="form-select form-select-sm" aria-label="Default select example"
                        onChange={(e) => setLimit(e.target.value)}>
                        <option value={50}>50</option>
                        <option value={200}>200</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                    </select>


                </div>
                <p> {entries} entries</p>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                        <li className={detail?.prevPage != null ? "page-item" : "page-item disabled"}>
                            <span className="page-link" onClick={() => setPage(detail?.prevPage)}><span
                                aria-hidden="true">&laquo;</span></span>
                        </li>
                        {/* {
                            pages && pages.map((el, key) => {
                                return <li className={currentPage == el ? "page-item active" : "page-item"} key={key}><a
                                    className="page-link"
                                    onClick={() => setPage(el)}>{el}</a>
                                </li>
                            })
                        } */}



                        <select className="form-select form-select-sm" aria-label="Default select example"
                            onChange={(e) => setPage(e.target.value)}>
                            {pages && pages.map((el, key) => {
                                return <option key={key} value={el}>{el}</option>
                            })
                            }
                        </select>




                        <li className={detail?.nextPage != null ? "page-item" : "page-item disabled"}>
                            <span className="page-link" onClick={() => setPage(detail?.nextPage)}><span
                                aria-hidden="true">&raquo;</span></span>
                        </li>
                    </ul>
                </nav>

            </div>


        </div>
    )
}